<template>
  <div>認証を処理しています...</div>
</template>

<script>
import { onMounted } from 'vue';

export default {
  name: 'AuthCallback',
  setup() {
    onMounted(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      if (code) {
        fetch(`/api/auth_callback.php?code=${code}`, {
            method: 'GET'
        })
        .then(response => response.json())
        .then(data => {
            if (data.loggedIn) {
                this.$router.push({ name: 'Menu' });
            } else {
                this.$router.push({ name: 'Login' });
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
      } else {
        console.log('認証コードがURLに含まれていません。');
      }
    });
  }
};
</script>
