<template>
    <v-app style="background-color: #d0d0d0;">
      <router-view></router-view>
    </v-app>
</template>

<script>
export default {
    name: 'App'
}
</script>

<style>

</style>
