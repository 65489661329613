import { createStore } from 'vuex';

export default createStore({
  state: {
    tableQueries: null, // この行を追加
    isLoggedIn: false,
    email: '',
    username: '',
    targetPeriod: '',
    targetCompany: '',
    currentActiveTab: null,
    queriesForStyle: [],
    allTabs: []  // すべてのタブの情報を保持する配列
  },
  mutations: {
    SET_QUERIES_FOR_STYLE(state, queriesForStyle) {
        state.queriesForStyle = queriesForStyle;
    },
    RESET_TABLE_QUERIES(state) {
        state.tableQueries = {}; // 初期化またはクリアする値によって異なる
    },
    RESET_QUERIES(state) {
        state.queries = {}; // 初期化またはクリアする値によって異なる
    },
    SET_QUERIES(state, queries) {
      state.queries = queries;
    },
    SET_TABLEQUERIES(state, tableQueries) {
        state.tableQueries = tableQueries;
      },
    login(state) {
      state.isLoggedIn = true;
    },
    logout(state) {
      state.isLoggedIn = false;
    },
    setLoginDetails(state, payload) {
      state.username = payload.username;
      state.email = payload.email; // emailをstateに追加する必要があります
      state.targetPeriod = payload.targetPeriod;
      state.targetCompany = payload.targetCompany;
    },
    setTargetPeriod(state, period) {
      state.targetPeriod = period;
    },
    setTargetCompany(state, company) { // 新しいmutationを追加
      state.targetCompany = company;
    },
    resetTargetPeriod(state) {
      state.targetPeriod = null; // または適切な初期値にリセット
    },
    SET_ACTIVE_TAB(state, tab) {
        state.currentActiveTab = tab;
        // console.log("Active tab set to:", state.currentActiveTab);
    },
    SET_ALL_TABS(state, tabs) {
        state.allTabs = tabs;  // すべてのタブの情報をセット
        // console.log("state.allTabs:", tabs);
    },
    ADD_TAB(state, newTab) {
        if (!state.allTabs.some(tab => tab.id === newTab.id)) {
            state.allTabs.push(newTab);
        }
    },
    REMOVE_TAB(state, tabIndex) {
        state.allTabs.splice(tabIndex, 1);
    },
    UPDATE_TAB(state, { tabIndex, updatedTab }) {
        state.allTabs[tabIndex] = updatedTab;
    }   
  },
  actions: {
    // ここで非同期のログイン処理やAPI呼び出しを行うことができます
    addTab({ commit, state }, tabId) {
        if (!state.allTabs.some(tab => tab.id === tabId)) {
          const tabData = { id: tabId, name: tabId, action: tabId };
          const updatedTabs = [...state.allTabs, tabData];
          commit('ADD_TAB', tabData);
          commit('SET_ACTIVE_TAB', tabData); // 追加したタブをアクティブにする
          commit('SET_ALL_TABS', updatedTabs);
          // console.log('Updated allTabs:', updatedTabs);
        }
    },
    removeTab({ commit, state }, tabName) {
        const tabIndex = state.allTabs.findIndex(tab => tab.name === tabName);
        if (tabIndex !== -1) {
            commit('REMOVE_TAB', tabIndex);
        }
    },
  },
  getters: {
    isAuthenticated(state) {
      return state.isLoggedIn;
    },
    loginDetails(state) {
      return {
        email: state.email,
        username: state.username,
        targetPeriod: state.targetPeriod,
        targetCompany: state.targetCompany
      };
    }
  }
});
