import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from '@/components/LoginPage.vue';
import HomePage from '@/components/HomePage.vue';
import LoginFailedPage from '@/components/LoginFailedPage.vue';
import MenuComponent from '@/components/MenuComponent.vue';
import AuthCallback from '@/components/AuthCallback.vue';
import store from '@/store';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
    meta: { requiresAuth: true }  // このルートは認証が必要です
  },
  {
    path: '/auth/callback',
    name: 'AuthCallback',
    component: AuthCallback,
    meta: { requiresAuth: false }  // 認証を必要としない
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage
  },
  {
    path: '/login-failed',
    name: 'LoginFailed',
    component: LoginFailedPage
  },
  {
    path: '/menu',
    name: 'Menu',
    component: MenuComponent,
    meta: { requiresAuth: true }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// ナビゲーションガード
router.beforeEach((to, from, next) => {
  // 認証が必要ない場合はチェックをスキップ
  if (to.matched.some(record => record.meta.requiresAuth === false)) {
    next();
    return;
  }

  const isLoggedIn = store.getters.isAuthenticated;  // Vuexストアからログイン状態を取得
  if (to.matched.some(record => record.meta.requiresAuth) && !isLoggedIn) {
    // このルートは認証が必要であり、ユーザーがログインしていないので、ログインページにリダイレクト
    next({ name: 'Login' });
  } else {
    next();  // 無条件にルートを許可
  }
});

export default router;
