<template>
    <div>
      <h2>Login</h2>
      <form @submit.prevent="login">
        <!-- 既存のログインフォーム -->
        <div>
          <label for="username">Username: </label>
          <input type="text" id="username" v-model="username">
        </div>
        <div>
          <label for="password">Password: </label>
          <input type="password" id="password" v-model="password">
        </div>
        <button type="submit">Login</button>
      </form>
  
      <!-- Googleログインボタン (GISを使用) -->
      <div id="g_id_onload"
           data-client_id="806323810787-rr27rf33ue5rkrioogaell1dbm85i94s.apps.googleusercontent.com"
           data-login_uri="http://localhost:8080/login"
           data-auto_prompt="false">
      </div>
      <div id="g_id_signin" class="g-signin2" data-width="240" data-height="50" data-longtitle="true"></div>
      <!-- 既存のマークアップ -->
      <button @click="loginWithMicrosoft">Login with Microsoft</button>
    </div>
  </template>
  
  <script>
  import { useStore } from 'vuex';
  import { onMounted, onBeforeUnmount } from 'vue';
  import { PublicClientApplication } from '@azure/msal-browser';

  export default {
    data() {
      return {
        username: '',
        password: ''
      };
    },
    setup() {
         // イベントリスナーを追加するための関数
        const addEventListener = () => {
        window.addEventListener('resize', handleResize);
        };

        // イベントリスナーのクリーンアップ（削除）を行う関数
        const removeEventListener = () => {
        window.removeEventListener('resize', handleResize);
        };

        // リサイズイベントが発生したときに実行される関数
        const handleResize = () => {
        console.log('Window is resized');
        };

        onMounted(() => {
        addEventListener();
        });

        onBeforeUnmount(() => {
        removeEventListener();
        });
        const store = useStore();
        return { store };
    },
    methods: {
      login() {
        // 既存のログインロジック
      },
      loginWithMicrosoft() {
        // Microsoftログインの実装
        const loginRequest = {
            scopes: ["User.Read"] // Microsoft Graph APIのスコープ
        };
        this.msalInstance.loginPopup(loginRequest)
            .then(loginResponse => {
                console.log(loginResponse); // ログイン成功時の処理
            // 必要に応じてユーザー情報を取得したり、バックエンドにトークンを送信したりする
            }).catch(error => {
                console.error(error); // ログイン失敗時のエラーハンドリング
            });
      },
      initializeGoogleSignIn() {
        if (typeof google !== 'undefined') {
            google.accounts.id.initialize({
                client_id: '806323810787-rr27rf33ue5rkrioogaell1dbm85i94s.apps.googleusercontent.com',
                callback: this.handleCredentialResponse
            });
            google.accounts.id.renderButton(
                document.getElementById('g_id_signin'),
                { theme: 'outline', size: 'large' }
            );
        }
      },
      handleCredentialResponse(response) {
        // console.log("Encoded JWT ID token: " + response.credential);
        // ここでバックエンドにトークンを送信し、ユーザー認証を行うなどの処理を追加
        // トークンをサーバーに送信する
        fetch('/api/authenticate.php', { // あなたのサーバーエンドポイントに置き換えてください
            method: 'POST',
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify({ token: response.credential })
        })
        .then(response => {
            if (response.ok) {
            return response.json();
            }
            throw new Error('Network response was not ok.');
        })
        .then(data => {
            // サーバーからの応答を処理します。例えば、認証が成功したか、ユーザー情報を更新するなど。
            // console.log('data:', data);
            let targetCompanyFormatted;

            // targetCompanyが配列で、要素が1つのみの場合、その要素を直接使用
            if (Array.isArray(data.targetCompany) && data.targetCompany.length === 1) {
                targetCompanyFormatted = data.targetCompany[0];
            } else {
                // targetCompanyが配列で、要素が2つ以上、または空の場合、配列そのものを使用
                targetCompanyFormatted = '会社選択';
            }

            this.$store.commit('setLoginDetails', {
                username: data.username, // サーバーから受け取ったユーザー名
                email: data.email, // サーバーから受け取ったemail
                targetCompany: targetCompanyFormatted, // 調整されたtargetCompanyの値
                targetPeriod: '' // 必要に応じて設定
            });
            // LoginPage.vue内
            this.$store.commit('login');
            // ログイン成功時の処理内
            this.$router.push({ name: 'Menu' });  // 例: this.$router.push({ name: 'Home' });
        })
        .catch(error => {
            console.error('There has been a problem with your fetch operation:', error);
            this.$router.push({ name: 'LoginFailed' });  // 例: this.$router.push({ name: 'Home' });
        });
      }
    },
    mounted() {
        // 既存のGoogleログイン初期化の呼び出し
        this.initializeGoogleSignIn();
        
        // MSALの初期化とインスタンスの作成
        this.msalInstance = new PublicClientApplication({
            auth: {
                clientId: "YOUR_CLIENT_ID_HERE", // Azure ポータルから取得したクライアント ID
                redirectUri: "http://localhost:8080" // リダイレクト URI
            }
        });
    }
  }
  </script>
  