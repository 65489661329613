<template>
    <div>
      <v-container fluid>
        <v-row align="center" no-gutters>
          
          <v-col cols="3" class="d-flex align-center justify-start px-1">
            <span class="custom-text" style="height: 25px;">
              会社ロゴ
            </span>
          </v-col>
  
          <v-col cols="3" class="d-flex align-center justify-start px-1">
             <!-- ログインユーザーボタン -->
             <v-btn
                id="userMenuActivator"
                text
                class="custom-text"
                style="height: 25px;"
                >
                ログインユーザー: {{ loginDetails.username }}
             </v-btn>

             <!-- ログインユーザーメニュー -->
             <v-menu
                v-model="userMenu"
                :close-on-content-click="false"
                activator="#userMenuActivator"
                >
                <v-list>
                    <v-list-item>
                    <v-list-item-title>{{ loginDetails.username }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                    <v-list-item-title>{{ loginDetails.email }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="generateAndCopyApiKey">
                    <v-list-item-title style="font-size: 14px;">API-KEY_FOR_EXCEL</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="logout">
                    <v-list-item-title style="font-size: 14px;">ログアウト</v-list-item-title>
                    </v-list-item>
                </v-list>
             </v-menu>
          </v-col>
          
          <v-col cols="3" class="d-flex align-center justify-start px-1">
              <!-- 対象会社が設定されている場合、対象会社ボタンを表示 -->
            <v-btn
                v-if="loginDetails.targetCompany"
                style="height: 25px; color: white;"
                text class="custom-text"
                id="selectCompany"
                color="secondary"
                @click="fetchCompany">
                対象会社: {{ loginDetails.targetCompany }}
            </v-btn>
            
            <!-- 対象会社が設定されていない場合、会社登録ボタンを表示 -->
            <v-btn
                v-else
                style="height: 25px; color: white;"
                text class="custom-text"
                color="red"
                @click="showCompanyRegistrationForm">
                会社登録
            </v-btn>
            
            <!-- 会社選択メニュー -->
            <v-menu activator="#selectCompany" v-if="loginDetails.targetCompany">
                <v-list>
                <v-list-item
                    v-for="item in companyOptions"
                    :key="item"
                    @click="changeTargetCompany(item)"
                    :style="{ backgroundColor: 'blue' }">
                    <v-list-item-title style="color: white">{{ item }}</v-list-item-title>
                </v-list-item>
                </v-list>
            </v-menu>
            <!-- 会社が設定されている場合でも表示される会社登録ボタン -->
            <v-btn
                v-if="loginDetails.targetCompany && userPermission === '1_admin'"
                style="height: 25px; color: white;"
                text class="custom-text"
                color="red"
                @click="showCompanyRegistrationForm">
                会社登録
            </v-btn>
          </v-col>

          <!-- 会社情報登録ダイアログ -->
          <v-dialog v-model="showRegistrationDialog" persistent max-width="600px">
            <v-card>
                <v-card-title>会社情報登録</v-card-title>
                <v-card-text>
                <v-text-field
                    label="会社cd(数値4桁)"
                    v-model="companyInfo.code"
                ></v-text-field>
                <v-text-field
                    label="会社名"
                    v-model="companyInfo.name"
                ></v-text-field>
                <v-text-field
                    label="開始年月(yyyymm)"
                    v-model="companyInfo.fiscalYearStart"
                ></v-text-field>
                <v-text-field
                    label="終了年月(yyyymm)"
                    v-model="companyInfo.fiscalYearEnd"
                ></v-text-field>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDialog">キャンセル</v-btn>
                <v-btn color="blue darken-1" text @click="registerCompany">登録</v-btn>
                </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- ワンタイムパスワード入力ダイアログ -->
          <v-dialog v-model="showOtpDialog" persistent max-width="500px">
            <v-card>
                <v-card-title>emailアドレスに送信した番号を入力</v-card-title>
                <v-card-text>
                <v-text-field
                    label="ワンタイムパスワード"
                    v-model="otp"
                ></v-text-field>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey" text @click="cancelOtp">キャンセル</v-btn>
                <v-btn color="green darken-1" text @click="verifyOtp">実行</v-btn>
                </v-card-actions>
            </v-card>
          </v-dialog>

          <v-col cols="3" class="d-flex align-center justify-start px-1">
            <v-btn
                style="height: 25px; color: black;"
                text class="custom-text"
                id="selectPeriods"
                color="secondary"
                @click="fetchPeriods"
                :disabled="!loginDetails.targetCompany">
                対象期間: {{ formattedTargetPeriod  }}
            </v-btn>

            <v-menu activator="#selectPeriods" :close-on-content-click="false" v-model="menuVisible">
                <v-list>
                    <v-list-item
                        v-for="item in periodOptions"
                        :key="item"
                        :style="{ backgroundColor: 'blue' }">
                        <v-list-item-title>
                            <v-checkbox
                                style="color: white; height: 35px;"
                                :label="item"
                                :value="item"
                                v-model="selectedPeriods"
                                density="compact"
                            ></v-checkbox>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                        <v-btn @click="applyPeriods">期間選択</v-btn>
                    </v-list-item>
                </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-row no-gutters>
        <v-col v-for="(menu, index) in menus" :key="index" class="px-1" cols="auto">
        <v-btn :ref="`btn-${index}`"  color="info" @click="showSubMenu(menu.name)" style="height: 30px;">{{ menu.label }}
            <v-menu activator="parent" v-model="menu.isOpen">
            <v-list>
                <v-list-item
                    v-for="(item, itemIndex) in menu.items"
                    :key="itemIndex"
                    @click="executeAction(item.action)"
                    :ref="`${menu.name}-${itemIndex}`"
                    :style="{ backgroundColor: 'blue', color: 'white' }">
                <v-list-item-title :style="menuItemStyle">{{ item.label }}</v-list-item-title>
                </v-list-item>
            </v-list>
            </v-menu>
        </v-btn>
        </v-col>
    </v-row>

    <v-card>
        <v-toolbar style="height: 45px;" density="compact" color="primary">
        <v-tabs v-model="currentTab">
            <draggable v-model="tabs" item-key="id" tag="div" group="tabs" @end="onEnd">
            <template #item="{element}">
                <v-tab :key="element.id" 
                    :class="{ 'custom-active-tab': currentTab === element.id }"
                    class="custom-tab"
                    @click="executeAction(element.action)" 
                    style="padding-top: 0;"> 
                <span class="tab-text" density="compact">{{element.name}}</span>
                <v-btn class="small-btn" @click.stop="removeTab(element.id)">☒</v-btn>
                </v-tab>
            </template>
            </draggable>
        </v-tabs>
        </v-toolbar>
        
        <v-window v-model="currentTab" @input="tabChanged" style="background-color: #ffffff;">
            <v-window-item v-for="tab in tabs" :key="tab.id" :value="tab.id">
                <div v-show="currentTab === tab.id">
                    <!-- タブの内容。例えば、各タブに関連するコンポーネントを表示 -->
                    <JournalEntryGrid ref="myGridRef" :active-tab="tab.name" :tabs="tabs" @request-tab-switch="executeAction" />
                </div>
            </v-window-item>
        </v-window>
    </v-card>
    <div v-if="showToast" class="toast">{{ toastMessage }}</div>
</template>
  
<script>
import { ref, watch, computed, nextTick } from 'vue';
import { useStore, mapState } from 'vuex';  
import JournalEntryGrid from './JournalEntryGrid.vue';
import draggable from 'vuedraggable';

export default {
    components: {
        JournalEntryGrid,
        draggable,
    },
    data() {
        return {
            showToast: false,
            toastMessage: '',
            userPermission: null, // ユーザーの権限
            showOtpDialog: false,
            otp: '',
            selectedPeriods: [],
            isMenuOpen: false, 
            userMenu: null,
            periodOptions: [],  // これは仮の選択肢です
            companyOptions: [],  // これも仮の選択肢です
            focusedMenuIndex: null, // フォーカスされているメニュー項目のインデックス
            showRegistrationDialog: false, // 会社情報登録ダイアログの表示状態
                companyInfo: { // 会社情報登録用のデータバインディング
                code: '',
                name: '',
                fiscalYearStart: '',
                fiscalYearEnd: '',
            },
            menus: [
                {
                name: '全般情報',
                label: 'q)全般情報',
                isOpen: false,
                items: [
                    { label: '1) 企業情報', action: '企業情報' },
                    { label: '2) ユーザー', action: 'ユーザー' },
                    { label: '3) 部門情報', action: '部門情報' },
                    { label: '4) 銀行情報', action: '銀行情報' },
                    { label: '5) 伝票番号', action: '伝票番号' }
                ]
                },
                {
                name: '科目情報',
                label: 'w)科目情報',
                isOpen: false,
                items: [
                    { label: '1) 科目一覧', action: '科目一覧' },
                    { label: '2) 補助科目', action: '補助科目' },
                    { label: '3) 表示区分', action: '表示区分' },
                    { label: '4) 税コード', action: '税コード' }
                ]
                },
                {
                name: '相手情報',
                label: 'r)相手情報',
                isOpen: false,
                items: [
                    { label: '1) 取引相手', action: '取引相手' },
                    { label: '2) 相手口座', action: '相手口座' },
                    { label: '3) 自社口座', action: '自社口座' },
                    { label: '4) PJ情報', action: 'PJ情報' }
                ]
                },
                {
                name: '仕訳画面',
                label: 'a)仕訳画面',
                isOpen: false,
                items: [
                    { label: '1) 仕訳画面', action: '仕訳画面' },
                    { label: '2) 債権画面', action: '債権画面' },
                    { label: '3) 債権推移', action: '債権推移' },
                    { label: '4) 債務画面', action: '債務画面' },
                    { label: '5) 債務推移', action: '債務推移' },
                    { label: '6) 源泉画面', action: '源泉画面' }
                ]
                },
                {
                name: '期首残高',
                label: 's)期首残高',
                isOpen: false,
                items: [
                    { label: '1) 補助残高', action: '補助残高' },
                    { label: '2) 相手残高', action: '相手残高' },
                    { label: '3) PJ残高', action: 'PJ残高' }
                ]
                },
                {
                name: 'TB画面',
                label: 'x)TB画面',
                isOpen: false,
                items: [
                    { label: '1) 勘定TB(発生)', action: '勘定TB(発生)' },
                    { label: '2) 勘定TB(残高)', action: '勘定TB(残高)' },
                    { label: '3) 補助TB(発生)', action: '補助TB(発生)' },
                    { label: '4) 補助TB(残高)', action: '補助TB(残高)' },
                    { label: '5) 相手TB(発生)', action: '相手TB(発生)' },
                    { label: '6) 相手TB(残高)', action: '相手TB(残高)' },
                    { label: '7) PJTB(発生)', action: 'PJTB(発生)' },
                    { label: '8) PJTB(残高)', action: 'PJTB(残高)' },
                ]
                },
                {
                name: '元帳画面',
                label: 'z)元帳画面',
                isOpen: false,
                items: [
                    { label: '1) 勘定元帳', action: '勘定元帳' },
                    { label: '2) 補助元帳', action: '補助元帳' },
                    { label: '3) 相手元帳', action: '相手元帳' },
                    { label: '4) PJ元帳', action: 'PJ元帳' }
                ]
                },
                {
                name: '分析画面',
                label: 'c)分析画面',
                isOpen: false,
                items: [
                    { label: '1) PJ分析', action: 'PJ分析' },
                    { label: '2) 部門PL', action: '部門PL' }
                ]
                },
                {
                name: 'その他等',
                label: 'v)その他等',
                isOpen: false,
                items: [
                    { label: '1) 翌期繰越処理', action: '翌期繰越処理' },
                    { label: '2) 決算期の変更', action: '決算期の変更' },
                    { label: '3) 消費税ck画面', action: '消費税ck画面' }
                ]
                },
                // 他のメニューも同様に追加可能
            ],
            menuItemStyle: {
                fontSize: '0.8rem'
            }
        };
    },
    setup() {
        const currentTab = ref(null);  // ここでcurrentTabを定義
        const menuVisible = ref(false); // refを使用してreactiveなプロパティを作成
        const store = useStore();
        const loginDetails = computed(() => store.getters.loginDetails);
        // console.log('loginDetails',loginDetails);
        const tabs = ref([]); // ここでタブのリストを定義
        
        const changeTargetPeriod = (item) => {
            store.commit('setTargetPeriod', item); // Vuexのステートを更新
        };
        
        const changeTargetCompany = async (item) => {
            store.commit('setTargetCompany', item); // Vuexのステートを更新
            store.commit('resetTargetPeriod');      // 対象期間をリセット
            // タブとウィンドウの状態をクリア
            tabs.value = [];       // タブのリストを空にする
            currentTab.value = null; // 現在のタブをnullに設定
            try {
                // 選択されている会社名をGETリクエストのクエリパラメータとして送信
                const companyName = encodeURIComponent(item);
                const response = fetch(`/api/get_periods.php?companyName=${companyName}`);
                // const data = response.json();
                console.log('response:',response);
            } catch (error) {
                console.error('Error fetching periods:', error);
            }
        };
        // const activeTab = computed(() => store.state.currentActiveTab);
        const activeTab = computed({
            get: () => store.state.currentActiveTab,
            set: (val) => store.commit('SET_ACTIVE_TAB', val)
        });

        const switchToTab = (tabName) => {
            const foundTab = tabs.value.find(tab => tab.name === tabName);
            if (foundTab) {
                currentTab.value = foundTab.id;
            }
        };
        const allTabsFromStore = computed(() => store.state.allTabs);
        
        // タブが変更されたときに反映させるための処理
        watch(allTabsFromStore, (newTabs) => {
            tabs.value = newTabs;
        });

        watch(activeTab, (newActiveTab) => {
            if (newActiveTab && newActiveTab.id) {
                currentTab.value = newActiveTab.id;
                nextTick(() => {
                    // console.log('myGridFef:', this.$refs.myGridRef);  
                    // if (this.$refs.myGridRef) {
                    //     this.$refs.myGridRef.deselectAllCells();
                    // }
                });
            }
        });

        return {
            tabs, // 他のプロパティと一緒に返す
            currentTab,  // ここでcurrentTabを返します
            switchToTab,
            menuVisible,
            loginDetails,
            changeTargetPeriod,
            changeTargetCompany,
            // ...他の必要なデータやメソッド...
        };
    },
    computed: {
        ...mapState(['allTabs', 'currentActiveTab']),
        isActiveTab(tab) {
            return this.currentActiveTab === tab.name;
        },
        formattedTargetPeriod() {
            const periods = this.loginDetails.targetPeriod;
            if (!Array.isArray(periods) || periods.length === 0) {return '未選択';}
            if (periods.length === 1) {return `[${periods[0]}]`;}
            if (periods.length === 2) {return `[${periods[1]}-${periods[0]}]`;}
            return `[${periods[periods.length - 1]}-${periods[0]}]`;
        }
    },
    methods:{
        async generateAndCopyApiKey() {
            const companyName = this.loginDetails.targetCompany;
            if (companyName === '会社選択'){
                alert('対象会社を選択して下さい'); return;
            } else if (!this.loginDetails.targetPeriod || this.loginDetails.targetPeriod.length === 0) {
                alert('対象期間を選択してください'); return;
            }
            try {
                // companyNameをクエリパラメータとして追加
                const url = new URL('/api/get_apiKey.php', window.location.origin);
                url.searchParams.append('companyName', companyName);

                const response = await fetch(url);
                const data = await response.json(); // APIキーが含まれる想定

                if (data.apiKey) {
                    // クリップボードにコピー
                    // const copy_data = companyName +'|'+ data.apiKey;
                    const copy_data = companyName +'|'+ this.loginDetails.targetPeriod +'|'+ data.apiKey;
                    await navigator.clipboard.writeText(copy_data);
                    // alert('APIキーがクリップボードにコピーされました。');
                    this.showToastWithTimer('APIキーがクリップボードにコピーされました。', 3000);
                    this.userMenu = false; // メニューを閉じる
                } else {
                    throw new Error('APIキーがレスポンスに含まれていません。');
                }
            } catch (error) {
                console.error('API-KEYの取得に失敗しました:', error);
                alert('API-KEYの取得に失敗しました。対象会社/対象期間を選択して下さい');
            }
        },
        showToastWithTimer(message, duration) {
            this.toastMessage = message;
            this.showToast = true;
            setTimeout(() => {
                this.showToast = false;
            }, duration);
        },
        onEnd() {
            // ドラッグ操作が完了したときの処理
        },
        showCompanyRegistrationForm() {
            this.showRegistrationDialog = true;
        },
        closeDialog() {
            this.showRegistrationDialog = false;
        },
        cancelOtp() {
            this.showOtpDialog = false; // OTP入力ダイアログを閉じる
            this.otp = ''; // OTP入力フィールドをクリア
        },
        async registerCompany() {
            // 入力バリデーション
            if (!/^\d{4}$/.test(this.companyInfo.code) || 
                !/^\d{6}$/.test(this.companyInfo.fiscalYearStart) ||
                !/^\d{6}$/.test(this.companyInfo.fiscalYearEnd) ||
                this.companyInfo.name.trim() === '') {
                alert('入力データが無効です。');
                return;
            }
            // fiscalYearStartとfiscalYearEndの差分が11か月以下かチェック
            const startYear = parseInt(this.companyInfo.fiscalYearStart.substring(0, 4));
            const startMonth = parseInt(this.companyInfo.fiscalYearStart.substring(4, 6));
            const endYear = parseInt(this.companyInfo.fiscalYearEnd.substring(0, 4));
            const endMonth = parseInt(this.companyInfo.fiscalYearEnd.substring(4, 6));
            // 年月から月数を計算
            const startTotalMonths = startYear * 12 + startMonth;
            const endTotalMonths = endYear * 12 + endMonth;
            // 差分を計算
            const monthsDifference = endTotalMonths - startTotalMonths;
            // 差分が0より大きく11以下ではない場合はエラー
            if (monthsDifference <= 0 || monthsDifference > 11) {
                alert('開始年月と終了年月が12か月超離れている場合は無効です。');
                return;
            }
            try {
                const response = await fetch('/api/register_company.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        userId: this.loginUserId, // サーバーサイドで検証するユーザーID
                        companyCode: this.companyInfo.code,
                        companyName: this.companyInfo.name,
                        fiscalYearStart: this.companyInfo.fiscalYearStart,
                        fiscalYearEnd: this.companyInfo.fiscalYearEnd
                    })
                });
                const data = await response.json();
                if (data.success) {
                    // this.loginDetails.targetCompany = this.companyInfo.name;
                    this.showRegistrationDialog = false;
                    this.$nextTick(() => {
                        this.showOtpDialog = true;
                    });
                } else {
                    alert('登録に失敗しました。');
                }
            } catch (error) {
                // console.error('Error registering company:', error);
                alert('登録に失敗しました。会社cdが登録済みでないか確認して下さい');
            }
        },
        async verifyOtp() {
            // OTPがブランクの場合にエラーを表示
            if (!this.otp.trim()) {
                alert('ワンタイムパスワードを入力してください。');
                return;
            }
            try {
                const response = await fetch('/api/confirm_registration.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ otp: this.otp })
                });
                if (!response.ok) {
                    throw new Error('ネットワークレスポンスが正しくありません。');
                }
                const data = await response.json();
                if (data.success) {
                    alert(data.message); // 成功メッセージを表示
                    this.showOtpDialog = false; // OTP入力ダイアログを閉じる
                    // ここで会社名をloginDetails.targetCompanyに設定
                    this.loginDetails.targetCompany = this.companyInfo.name;

                } else {
                    alert(data.message); // サーバーからのエラーメッセージを表示
                }
            } catch (error) {
                console.error('Error verifying OTP:', error);
                alert('ワンタイムパスワード検証中にエラーが発生しました。'); // エラーアラート
            }
        },
        tabChanged(tabId) {
            const tab = this.tabs.find(t => t.id === tabId);
            if (tab) {
                this.$store.commit('SET_ACTIVE_TAB', tab.name);
            }
            this.$nextTick(() => {
                // console.log('refs:', this.$refs.myGridRef);
                const gridRefs = this.$refs.myGridRef;
                if (gridRefs) {
                    gridRefs.forEach(gridRef => {
                        if (typeof gridRef.deselectAllCells === 'function') {
                            gridRef.deselectAllCells();
                        }
                    });
                }
            });
        },
        async removeTab(tabId) {
            const tabs1 = this.tabs;
            // const tabIndex = this.tabs.findIndex(tab => tab.id === tabId);
            const tabs = this.$store.state.allTabs; // Vuex ストアから allTabs を取得
            const tabs2 = tabs;
            let tabIndex = tabs.findIndex(tab => tab.id === tabId);

            if (tabIndex !== -1) {
                // Vuex ストアのミューテーションを使用してタブを削除
                await this.$store.dispatch('removeTab', tabId);
                
                if (tabs1 !== tabs2) {
                    // なぜかズレるので例外処理を入れる
                    let tabIndex = tabs1.findIndex(tab => tab.id === tabId);
                    if (tabIndex !== -1) {
                        this.tabs.splice(tabIndex, 1); 
                    }
                }
                
                this.$nextTick(() => {
                    const updatedTabs = this.$store.state.allTabs; // タブが更新された後の状態を取得
                    if (updatedTabs.length > 0) {
                        // もし削除されたタブが最後のタブであれば、新しい最後のタブをアクティブにする
                        this.currentTab = (tabIndex < updatedTabs.length) ? updatedTabs[tabIndex].id : updatedTabs[updatedTabs.length - 1].id;
                    } else {
                        this.currentTab = ''; // タブがない場合は currentTab を空文字列に設定
                    }
                    this.tabChanged(this.currentTab); // 新しいアクティブなタブを反映
                });
            }
        },
        addTab(tabId) {
            // タブが存在しない場合にのみ追加します
            if (!this.tabs.some(tab => tab.id === tabId)) {
                const tabData = { id: tabId, name: tabId, action: tabId };
                this.tabs.push(tabData);
                
                // Vuex の allTabs にもタブを追加
                this.$store.commit('ADD_TAB', tabData);
                // this.currentTab = tabId;
                // this.tabChanged(this.currentTab);
            }
        },
        updateTab(tabIndex, updatedTab) {
            this.$store.commit('UPDATE_TAB', { tabIndex, updatedTab });
            this.tabs[tabIndex] = updatedTab;
        },
        applyPeriods() {
            this.menuVisible = false; // メニューを閉じる
            // 選択された期間を数値に変換しソート
            const sortedPeriods = this.selectedPeriods
                .map(period => parseInt(period, 10))
                .sort((a, b) => a - b);
            // 連続しているかチェック
            let isConsecutive = true;
            for (let i = 1; i < sortedPeriods.length; i++) {
                if (sortedPeriods[i] !== sortedPeriods[i - 1] + 100) { // 100は年の差を示しています。
                    isConsecutive = false;
                    break;
                }
            }
            if (isConsecutive) {
                this.$store.commit('setTargetPeriod', this.selectedPeriods);
            } else {
                // エラーメッセージを表示（必要に応じて修正）
                console.log('selectedPeriods:',this.selectedPeriods);
                alert("選択された期間は連続していません。連続した期間を選択してください。");
            }
        },
        async fetchCompany() {
            this.selectedPeriods = [];
            try {
                const response = await fetch('/api/get_company.php');
                const data = await response.json();
                this.companyOptions = data.map(item => item.companyName); // a05 の値のみを抽出
                // console.log('get_company_',this.companyOptions);
            } catch (error) {
                alert('ログアウトされました。ログインしなおしてください');
                console.error('Error fetching company:', error);
            }
        },
        setTargetCompany(company) {
            this.loginDetails.targetCompany = company;
        },
        async fetchPeriods() {
            try {
                // 選択されている会社名をGETリクエストのクエリパラメータとして送信
                const companyName = encodeURIComponent(this.loginDetails.targetCompany);
                const response = await fetch(`/api/get_periods.php?companyName=${companyName}`);
                const data = await response.json();
                // this.periodOptions = data.map(item => item['エンド月']);
                this.periodOptions = data.periods.map(item => item['エンド月']).sort((a, b) => b - a);
                this.userPermission = data.permission;
                // console.log('permission',data.permission);
            } catch (error) {
                console.error('Error fetching periods:', error);
            }
        },
        setTargetPeriod(period) {
            this.loginDetails.targetPeriod = period;
        },
        async logout() {
            try {
                // サーバーサイドでのログアウト処理を呼び出し
                await fetch('/api/logout.php', { method: 'POST' });
                // ログアウト成功後の処理
                this.$store.commit('logout'); // ログアウト状態に更新
                this.$router.push({ name: 'Login' }); // ログインページにリダイレクト
            } catch (error) {
                console.error('Logout failed:', error);
            }
            this.$store.commit('logout'); // ログアウト状態に更新
            this.$router.push({ name: 'Login' }); // ログインページにリダイレクト
        },
        doNothing(){
            '何もしない(仮)'
        },
        showSubMenu(menuIndex) {
            if (this.menus[menuIndex] && !this.menus[menuIndex].isOpen) {
                this.menus[menuIndex].isOpen = true;
            }
        },
        closeMenu(action) {
            for (let menu of this.menus) {
                for (let item of menu.items) {
                    if (item.action === action) {
                        menu.isOpen = false;
                        return; // actionを見つけたらループを終了
                    }
                }
            }
        },
        async carryOverNextYear(){
            let maxPeriod = Math.max(...this.selectedPeriods); 
            console.log(maxPeriod);
            if (typeof maxPeriod !== 'number' || isNaN(maxPeriod) || !isFinite(maxPeriod)) {
                alert('対象期間を再度選択して下さい');
                return;
            }
            const year = Math.floor(maxPeriod / 100);
            const month = maxPeriod % 100;
            const nextPeriod = (year+1)*100+month;
            // console.log(maxPeriod, nextPeriod, year, month);

            const confirmAction = window.confirm(`本当に翌期繰越を実行しますか？対象年度は ${maxPeriod} です。`);
            if (!confirmAction) {
                return; // ユーザーが更新をキャンセルした場合
            }
            const userInput = prompt('西暦6桁を入力して下さい', nextPeriod);
            // 入力が数値で、長さが6であるかどうか
            if (!userInput) {
                alert('入力が必要です。'); return;
            }
            const isNumber = !isNaN(userInput) && userInput.trim().length === 6 && !isNaN(parseFloat(userInput));
            if (!isNumber) {
                alert('入力が無効です。西暦6桁の数値を入力してください。'); return false;
            }

            // postDataオブジェクトにmaxPeriodとuserInputを格納
            const postData = JSON.stringify({
                currentPeriod: maxPeriod,
                nextPeriod: userInput*1
            });
            // POSTリクエストを送信
            try {
                const response = await fetch('/api/carryOver.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: postData
                });
                const data = await response.json();
                // サーバーからの応答に基づいて何かをする
                if (data === "success") {
                    // 応答が "success" の場合、ユーザーに成功メッセージを表示
                    this.$emit('show-success-message', '繰越処理が成功しました。');
                } else {
                    // 応答が "success" 以外の場合、エラーメッセージを表示
                    this.$emit('show-error-message', '繰越処理に失敗しました。');
                }
            } catch (error) {
                console.error('繰越処理の実行中にエラーが発生しました:', error);
                this.$emit('show-error-message', '通信エラーが発生しました。');
            }
        },
        executeAction(action) {            
            // selectCompanyがブランクの場合は警告を表示し、処理を中断
            if (!this.loginDetails.targetCompany) {
                alert('会社を選択してください');
                return;
            } else if (!this.loginDetails.targetPeriod || this.loginDetails.targetPeriod.length === 0) {
                alert('対象期間を選択してください');
                return;
            }

            if (this.userPermission==='2_general') {
                if (action==='ユーザー') {
                    alert('権限がありません');
                    return;
                }
            }

            switch(action) {
            case '企業情報':case 'ユーザー': case '部門情報':case '銀行情報':case '伝票番号': 
            case '科目一覧':case '補助科目':case '表示区分': case '税コード':
            case '取引相手': case '相手口座':case '自社口座':case 'PJ情報': 
            case '仕訳画面':case '元帳画面': case '債権画面':case '債権推移':case '債務画面': case '債務推移':case '源泉画面':
            case '補助残高': case '相手残高':case 'PJ残高':
            case '勘定TB(発生)': case '補助TB(発生)':case '相手TB(発生)':case 'PJTB(発生)': 
            case '勘定TB(残高)': case '補助TB(残高)':case '相手TB(残高)':case 'PJTB(残高)': 
            case '勘定元帳': case '補助元帳':case '相手元帳':case 'PJ元帳': 
            case 'PJ分析': case '部門PL': case '消費税ck画面':
                this.addTab(action);
                this.currentTab = action; // タブの値を'journalEntry'に設定
                this.tabChanged(this.currentTab); // ここで tabChanged を呼び出します
                this.closeMenu(action);
                break;
                // 他のメニューアクションに対する処理をここに追加...
            case '翌期繰越処理':
                this.carryOverNextYear();
                break;
            default:
                if (action.includes('仕訳画面')||action.includes('元帳')) {
                    this.addTab(action);
                    this.currentTab = action; // タブの値を'journalEntry'に設定
                    this.tabChanged(this.currentTab); // ここで tabChanged を呼び出します
                    this.closeMenu(action);
                } else {
                    console.log('未知のアクション:', action);
                }
            }
        },
        handleKeydown(event) {
            // if (event.key === 'Enter') {
            //     event.preventDefault();
            //     return;
            // }
            const matchedMenuIndex = this.menus.findIndex(menu => {
                // return event.ctrlKey && event.shiftKey && event.altKey && event.code === `Key${menu.label[0].toUpperCase()}`;
                return event.altKey && event.code === `Key${menu.label[0].toUpperCase()}`;
            });
            if (matchedMenuIndex > -1) {
                this.$refs[`btn-${matchedMenuIndex}`][0].$el.focus();
                this.showSubMenu(matchedMenuIndex);  // この行を追加します。
            }
        },
    },
    created() {
      window.addEventListener('keydown', this.handleKeydown);
    },
    unmounted() {
      window.removeEventListener('keydown', this.handleKeydown);
    },
    mounted() {
        if (this.tabs.length > 0) {
            this.currentTab = this.tabs[0].id;
            // console.log('currentTab:',this.currentTab);
        }
    },
};
</script>

<style scoped>
.custom-text {
    font-size: 0.8rem;  /* Adjusted font size */
    line-height: 1.2;  
}
.tight-list .v-list-item {
    padding: 0.5px 0;  /* これは例です。適切な値に調整してください */
}
v-btn {
  position: relative; /* or 'fixed' depending on the desired effect */
  /* Add any other styles if needed */
}
.custom-active-tab {
  background-color: #4CAF50;
  color: white;
}
.v-tab:hover {
  color: black !important;
}
.tab-text {
    line-height: 1; /* line-height調整 */
    vertical-align: top; /* vertical-align調整 */
}
.small-btn {
    height: 20px;
    min-width: 20px;
    padding: 0 3px; /* padding調整 */
    line-height: 1; /* line-height調整 */
    vertical-align: top; /* vertical-align調整 */
}
.toast {
  /* トースト通知のスタイル */
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 5px;
}
.v-menu {
  overflow: hidden;
}
</style>